import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useParams, useNavigate } from 'react-router-dom';
import { fetchOrder, updateOrder } from '../redux/actions/thunks/Orders';
import { FaCheck } from 'react-icons/fa';
import { fetchStorage } from '../redux/actions/thunks/Storage';
import { fetchUsers } from '../redux/actions/thunks/Auth.js';
import { getWeek, getYear } from 'date-fns';
import CustomDropdown from './CustomDropdown';
import NameInputModal from './NameInputModal';
import { orderModified } from '../redux/actions/ordersAction.js';
import LoadingIcon from './LoadingIcon';

const OrderPage = () => {
    const { orderId } = useParams();
    const dispatch = useDispatch();
    const order = useSelector(state => state.orders.currentOrder);
    const navigate = useNavigate();
    const notifications = useSelector(state => state.notification);
    const storage = useSelector(state => state.storage.storage);
    const [selectedStairIndex, setSelectedStairIndex] = useState(null);
    const [selectedStepIndex, setSelectedStepIndex] = useState(null);
    const [selectedPlatformIndex, setSelectedPlatformIndex] = useState(null);
    const [selectedSquarePlatformIndex, setSelectedSquarePlatformIndex] = useState(null);
    const [selectedHandrailIndex, setSelectedHandrailIndex] = useState(null);
    const [selectedOtherIndex, setSelectedOtherIndex] = useState(null);
    const [selectedTab, setSelectedTab] = useState('Suunnittelu'); // State to track selected tab
    const [showNameModal, setShowNameModal] = useState(false);
    const [currentNameType, setCurrentNameType] = useState('');
    const [isModified, setIsModified] = useState(false);
    const currentUser = useSelector(state => state.users.currentUser);

    const [formData, setFormData] = useState({
        isRemoved: false,
        notes: '',
        stairs: [
            {
                stairName: '',
                triangleWoodType: '',
                stepCutting: '',
                stepCutting_modifiedTimestamp: null,
                stepGluing: '',
                stepGluing_modifiedTimestamp: null,
                stepSanding: '',
                stepSanding_modifiedTimestamp: null,
                stepColoring1: '',
                stepColoring1_modifiedTimestamp: null,
                stepColoring2: '',
                stepColoring2_modifiedTimestamp: null,
                stepColoring3: '',
                stepColoring3_modifiedTimestamp: null,
                stepPackaging: '',
                stepPackaging_modifiedTimestamp: null,
                handRail_modifiedTimestamp: null,
                handRail: '',
                ledStrips: '',
                lStrips: '',
                ledStrips_modifiedTimestamp: null,
                lStrips_modifiedTimestamp: null,
                stepColor: '',
                ledColor: '',
                stepGroove: '',
                stairsModel: '',
                U_stairs: false,
                L_stairs: false,
                lowerCoverWoodType: '',
                straightSteps: [
                    { stepName: '', stepWoodType: '', stepAmount: 0, stepLength: 0, stepDepth: 0 }
                ],
                platforms: [
                    { platformName: '', platformWoodType: '', platformAmount: 0, platformLength: 0 }
                ],
                squarePlatforms: [
                    { squarePlatformName: '', squarePlatformWoodType: '', squarePlatformAmount: 0, squarePlatformLength: 0 }
                ],
                handrails: [
                    { handrailName: '', handrailWoodType: '', handrailModel: '', handrailAmount: 0 }
                ],
                others: [
                    { otherName: '', otherWoodType: '', otherLength: 0, otherDepth: 0, otherThickness: 0, otherAmount: 0 }
                ]
            }
        ]
    });

    const selectedStair = formData.stairs[selectedStairIndex];
    const selectedStraightSteps = selectedStair?.straightSteps[selectedStepIndex];
    const selectedPlatform = selectedStair?.platforms[selectedPlatformIndex];
    const selectedSquarePlatform = selectedStair?.squarePlatforms[selectedSquarePlatformIndex];
    const selectedHandrails = selectedStair?.handrails[selectedHandrailIndex];
    const selectedOther = selectedStair?.others[selectedOtherIndex];

    const updatedStairs = [...formData.stairs];
    const [loadingOrder, setLoadingOrder] = useState(false);
    const [savingOrder, setSavingOrder] = useState(false);
    const [isTriangleDisabled, setIsTriangleDisabled] = useState(false);

    useEffect(() => {

        if (notifications.message === 'Haetaan tilausta...') {
            setLoadingOrder(true);
        } else if (notifications.message === 'Tilaus haettu onnistuneesti') {
            setLoadingOrder(false);
        }

        if (notifications.message === 'Päivitetään tilausta...') {
            setSavingOrder(true);
        } else if (notifications.message === 'Tilaus päivitetty onnistuneesti') {
            setSavingOrder(false);
        }

    }, [notifications]);

    useEffect(() => {
        // Fetch the single order when the component mounts
        dispatch(fetchUsers());
        dispatch(fetchStorage());
        dispatch(fetchOrder(orderId));
    }, [dispatch, orderId]); // Include orderId in the dependency array

    useEffect(() => {
        if (order && order.stairs) {
            // Initialize formData with the first stair or an empty object
            setFormData(prevFormData => ({
                ...prevFormData,
                stairs: order.stairs,
                notes: order.notes,
                isRemoved: order.isRemoved
            }));
            if (order.stairs.length > 0) {
                setSelectedStairIndex(0);
                setFormData(prevFormData => ({
                    ...prevFormData,
                    ...order.stairs[0]
                }));
            }
        }
    }, [order]);

    useEffect(() => {
        if (selectedStairIndex !== null && formData.stairs.length > 0) {
            // Set the first index as selected for each type if the list is not empty
            if (selectedStair.straightSteps.length > 0 && selectedStepIndex === null) {
                setSelectedStepIndex(0);
            }
            if (selectedStair.platforms.length > 0 && selectedPlatformIndex === null) {
                setSelectedPlatformIndex(0);
            }
            if (selectedStair.squarePlatforms.length > 0 && selectedSquarePlatformIndex === null) {
                setSelectedSquarePlatformIndex(0);
            }
            if (selectedStair.handrails.length > 0 && selectedHandrailIndex === null) {
                setSelectedHandrailIndex(0);
            }
            if (selectedStair.others.length > 0 && selectedOtherIndex === null) {
                setSelectedOtherIndex(0);
            }
        }
    }, [selectedStair]);

    const handleStairChange = (index) => {
        setSelectedStairIndex(index);
        if (formData.stairs[index]) {
            setFormData(prevFormData => ({
                ...prevFormData,
                ...formData.stairs[index] // Ensure to use formData here
            }));
        }
    };
    const addStair = (name) => {
        const newStair = {
            stairName: name,
            others: [],
            straightSteps: [],
            platforms: [],
            squarePlatforms: [],
            handrails: [],
            triangleWoodType: '',
            stepCutting: '',
            stepCutting_modifiedTimestamp: null,
            stepGluing: '',
            stepGluing_modifiedTimestamp: null,
            stepSanding: '',
            stepSanding_modifiedTimestamp: null,
            stepColoring1: '',
            stepColoring1_modifiedTimestamp: null,
            stepColoring2: '',
            stepColoring2_modifiedTimestamp: null,
            stepColoring3: '',
            stepColoring3_modifiedTimestamp: null,
            stepPackaging: '',
            stepPackaging_modifiedTimestamp: null,
            handRail_modifiedTimestamp: null,
            handRail: '',
            ledStrips: '',
            lStrips: '',
            ledStrips_modifiedTimestamp: null,
            lStrips_modifiedTimestamp: null,
            stepColor: '',
            ledColor: '',
            stepGroove: '',
            stairsModel: '',
            U_stairs: false,
            L_stairs: false,
            lowerCoverWoodType: '',
        };

        setSelectedStairIndex(formData.stairs.length);

        // Update formData locally with the new stair
        setFormData({
            ...formData,
            stairs: [...formData.stairs, newStair]  // Add the new stair to formData
        });

        setIsModified(true);
    };

    const handleDeleteStair = (index) => {
        const updatedStairs = formData.stairs.filter((_, i) => i !== index);
        setFormData({ ...formData, stairs: updatedStairs });

        if (updatedStairs.length > 0 && selectedStairIndex === index) {
            setSelectedStairIndex(0);
        } else if (updatedStairs.length > 0 && selectedStairIndex !== index) {
            setSelectedStairIndex(Math.max(selectedStairIndex - 1, 0));
        } else {
            setSelectedStairIndex(null);
        }
        setIsModified(true);
    };

    const handleDeleteItem = (index, itemType) => {
        setFormData(prevFormData => {
            const updatedStairs = [...prevFormData.stairs];
            const selectedStair = updatedStairs[selectedStairIndex];

            switch (itemType) {
                case 'straightSteps':
                    selectedStair.straightSteps.splice(index, 1);
                    if (selectedStair.straightSteps.length > 0 && selectedStepIndex === index) {
                        setSelectedStepIndex(0);
                    } else if (selectedStair.straightSteps.length > 0 && selectedStepIndex !== index) {
                        setSelectedStepIndex(Math.max(selectedStepIndex - 1, 0));
                    } else {
                        setSelectedStepIndex(null);
                    }
                    break;

                case 'platforms':
                    selectedStair.platforms.splice(index, 1);
                    if (selectedStair.platforms.length > 0 && selectedPlatformIndex === index) {
                        setSelectedPlatformIndex(0);
                    } else if (selectedStair.platforms.length > 0 && selectedPlatformIndex !== index) {
                        setSelectedPlatformIndex(Math.max(selectedPlatformIndex - 1, 0));
                    } else {
                        setSelectedPlatformIndex(null);
                    }
                    break;

                case 'squarePlatforms':
                    selectedStair.squarePlatforms.splice(index, 1);
                    if (selectedStair.squarePlatforms.length > 0 && selectedSquarePlatformIndex === index) {
                        setSelectedSquarePlatformIndex(0);
                    } else if (selectedStair.squarePlatforms.length > 0 && selectedSquarePlatformIndex !== index) {
                        setSelectedSquarePlatformIndex(Math.max(selectedSquarePlatformIndex - 1, 0));
                    } else {
                        setSelectedSquarePlatformIndex(null);
                    }
                    break;

                case 'handrails':
                    selectedStair.handrails.splice(index, 1);
                    if (selectedStair.handrails.length > 0 && selectedHandrailIndex === index) {
                        setSelectedHandrailIndex(0);
                    } else if (selectedStair.handrails.length > 0 && selectedHandrailIndex !== index) {
                        setSelectedHandrailIndex(Math.max(selectedHandrailIndex - 1, 0));
                    } else {
                        setSelectedHandrailIndex(null);
                    }
                    break;
                case 'others':
                    selectedStair.others.splice(index, 1);
                    if (selectedStair.others.length > 0 && selectedOtherIndex === index) {
                        setSelectedOtherIndex(0);
                    } else if (selectedStair.others.length > 0 && selectedOtherIndex !== index) {
                        setSelectedOtherIndex(Math.max(selectedOtherIndex - 1, 0));
                    } else {
                        setSelectedOtherIndex(null);
                    }
                    break;

                default:
                    console.error('Unknown item type');
                    return prevFormData;
            }

            updatedStairs[selectedStairIndex] = selectedStair;
            return { ...prevFormData, stairs: updatedStairs };
        });
        setIsModified(true);
    };

    useEffect(() => {
        if (currentUser.role === "design") {
            setSelectedTab('Suunnittelu')
        }
        if (currentUser.role === "production") {
            setSelectedTab('Tuotanto')
        }
    }, [currentUser]); // Include orderId in the dependency array

    const [isSubmitted, setIsSubmitted] = useState(false);

    const handleFormDataModification = (e) => {
        const { name, value, type, checked } = e.target;
        const inputValue = type === 'checkbox' ? checked : value;

        setFormData({
            ...formData,
            [name]: inputValue
        });
        setIsModified(true);
    };

    const handleStairModification = (e) => {
        const { name, value, type, checked } = e.target;
        const inputValue = type === 'checkbox' ? checked : value;

        if (inputValue === 'Leiju') {
            setIsTriangleDisabled(true);
        } else {
            setIsTriangleDisabled(false);
        }

        // Make sure a stair is selected
        if (selectedStairIndex === null || selectedStairIndex < 0) {
            return; // No stair selected, do nothing
        }

        const updatedStairs = [...formData.stairs];
        const isFieldCleared = inputValue === '';

        // Handle mutually exclusive checkboxes for U_stairs and L_stairs
        let newStairState = {
            ...updatedStairs[selectedStairIndex],
            [name]: inputValue,
            [`${name}_modifiedTimestamp`]: isFieldCleared ? null : new Date(), // Update modified timestamp
        };

        if (name === 'U_stairs' || name === 'L_stairs') {
            newStairState = {
                ...newStairState,
                U_stairs: name === 'U_stairs' ? checked : false,
                L_stairs: name === 'L_stairs' ? checked : false,
            };
        }

        // Update the selected stair with the modified values
        updatedStairs[selectedStairIndex] = newStairState;

        setFormData({
            ...formData,
            stairs: updatedStairs,
        });

        setIsModified(true)
    };

    const handleWoodObjectModification = (e, objectType) => {
        const { name, value } = e.target;

        // Ensure the selected indices are properly referenced
        const stairIndex = selectedStairIndex;

        // Map objectType to the corresponding selected index
        const objectIndexMap = {
            straightSteps: selectedStepIndex,
            platforms: selectedPlatformIndex,
            squarePlatforms: selectedSquarePlatformIndex,
            handrails: selectedHandrailIndex,
            others: selectedOtherIndex
        };

        const objectIndex = objectIndexMap[objectType];

        // Ensure the selected stair and object exist
        if (stairIndex === null || stairIndex < 0 || objectIndex === null || objectIndex < 0) {
            return; // Exit if indices are invalid
        }

        // Clone the stairs array
        const updatedStairs = [...formData.stairs];
        const updatedObjectArray = [...updatedStairs[stairIndex][objectType]];

        // Update the specific object within the array
        updatedObjectArray[objectIndex] = {
            ...updatedObjectArray[objectIndex],
            [name]: value, // Update the field with the new value
        };

        // Update the stair object with the modified array
        updatedStairs[stairIndex] = {
            ...updatedStairs[stairIndex],
            [objectType]: updatedObjectArray,
        };

        // Set the updated form data
        setFormData({
            ...formData,
            stairs: updatedStairs,
        });
        setIsModified(true);
    };

    const handleOtherModification = (values, objectType) => {
        const stairIndex = selectedStairIndex;
    
        // Map objectType to the corresponding selected index
        const objectIndexMap = {
            others: selectedOtherIndex
        };
    
        const objectIndex = objectIndexMap[objectType];
    
        // Ensure the selected stair and object exist
        if (stairIndex === null || stairIndex < 0 || objectIndex === null || objectIndex < 0) {
            return; // Exit if indices are invalid
        }
    
        // Clone the stairs array
        const updatedStairs = [...formData.stairs];
        const updatedObjectArray = [...updatedStairs[stairIndex][objectType]];
    
        // Update the specific object within the array
        updatedObjectArray[objectIndex] = {
            ...updatedObjectArray[objectIndex],
            ...values // Spread the new values to update multiple fields
        };
    
        // Update the stair object with the modified array
        updatedStairs[stairIndex] = {
            ...updatedStairs[stairIndex],
            [objectType]: updatedObjectArray,
        };
    
        // Set the updated form data
        setFormData({
            ...formData,
            stairs: updatedStairs,
        });
        setIsModified(true);
    };
    
    const formatTimestamp = (timestamp) => {
        if (!timestamp) return ''; // Return empty string if timestamp is not available
        const date = new Date(timestamp);
        const options = {
            year: 'numeric',
            month: '2-digit',
            day: '2-digit',
            hour: '2-digit',
            minute: '2-digit',
        };
        return date.toLocaleString('fi-FI', options);
    };

    const formatWeekNumber = (dateString) => {
        const date = new Date(dateString);
        const weekNumber = getWeek(date);
        const year = getYear(date);
        return `Viikko ${weekNumber}/${year}`;
    };

    const formattedDate = formatWeekNumber(order.delivery);

    // Extract unique wood types from storage
    const otherSizes = storage
        .filter(item => item.woodType === selectedOther?.otherWoodType)
        .map(item => ({ length: item.length, depth: item.depth, thickness: item.thickness }));
    const otherWoodTypes = [
        ...new Set(
            storage
                .map(item => item.woodType)
                .filter(type =>
                    type !== null &&
                    type !== 'Käsijohteet' &&
                    type !== 'Muut'
                )
        )
    ];
    const handrail_woodTypes = [...new Set(storage.map(item => item.handRail_woodType).filter(type => type !== null))];
    const handrailModels = [...new Set(storage.map(item => item.handRailModel).filter(type => type !== null))];
    const woodTypesStep = [
        ...new Set(
            storage
                .map(item => item.woodType)
                .filter(type =>
                    type !== null &&
                    type !== 'Käsijohteet' &&
                    type !== 'Muut' &&
                    !type.includes('MDF')
                )
        )
    ];
    const woodTypesTriangleStep = [
        ...new Set(
            storage
                .map(item => item.woodType)
                .filter(type =>
                    type !== null &&
                    type !== 'Käsijohteet' &&
                    type !== 'Muut' &&
                    !type.includes('MDF') &&
                    !type.includes('aihio Valo') &&
                    !type.includes('aihio Kide')
                )
        )
    ];
    const woodTypesPlatform = [
        ...new Set(
            storage
                .map(item => item.woodType)
                .filter(type =>
                    type !== null &&
                    type !== 'Käsijohteet' &&
                    type !== 'Muut' &&
                    !type.includes('MDF') &&
                    !type.includes('aihio')
                )
        )
    ];
    const woodTypesLowercover = [
        ...new Set(
            storage
                .map(item => item.woodType)
                .filter(type =>
                    type !== null &&
                    type !== 'Käsijohteet' &&
                    type !== 'Muut' &&
                    !type.includes('aihio') &&
                    !type.includes('sormijatkettu')
                )
        )
    ];
    // Define stairs models
    const stairsModels = ['Lumo', 'Kide', 'Valo', 'Leiju', 'Kannel', 'Umpiporras'];

    // Function to handle cancel button
    const handleCancel = () => {
        if (isModified) {
            const confirmMessage = 'Muokkauksia ei ole tallennettu. Haluatko poistua sivulta tallentamatta?';
            if (window.confirm(confirmMessage)) {
                navigate(-1);
            }
        } else {
            navigate(-1);
        }
    };

    useEffect(() => {
        dispatch(orderModified(isModified));
    }, [isModified, dispatch]);

    useEffect(() => {
        if (notifications.message === 'Tilaus päivitetty onnistuneesti') {
            setIsSubmitted(true);
            dispatch(fetchOrder(orderId));

            // Set isSubmitted to false after 5 seconds
            setTimeout(() => {
                setIsSubmitted(false);
            }, 5000);
        }
    }, [notifications, dispatch, orderId]);

    const handleSubmit = (e) => {
        e.preventDefault();
        console.log("submitting");

        const { stairs } = formData;
        let isValid = true; // Initialize a flag to track validation status

        // Validate the form data
        if (formData.stairs && formData.stairs.length > 0) {
            let requiredThickness;
            // Validate straight steps
            stairs.forEach((stair) => {
                const { stairsModel } = stair;
                stair.straightSteps.forEach((step) => {
                    if (!step.stepWoodType) {
                        alert(`Valitse puulaji askelmille (${step.stepName}), (${stair.stairName}) `);
                        isValid = false; // Set flag to false if validation fails
                    } else {
                        let matchingStorage;
                        if (stairsModel === 'Leiju') {
                            // Filter storage based on woodType and stepLength
                            matchingStorage = storage.filter(item => item.woodType === step.stepWoodType && item.length >= step.stepLength && item.thickness === 20);
                        } else {
                            // Filter storage based on woodType and stepLength
                            matchingStorage = storage.filter(item => item.woodType === step.stepWoodType && item.length >= step.stepLength && (item.thickness === 100 || item.thickness === 90 || item.thickness === 40));
                        }


                        // If no matching storage found for the step
                        if (matchingStorage.length === 0) {
                            alert(`Suoriin askelmiin vaadittavaa puuta ei löydy varastosta (${stair.stairName}): ${step.stepWoodType} ${step.stepLength}. Lisää tuote varastoon.`);
                            isValid = false; // Set flag to false if validation fails
                        }

                        // Check for specific lower cover wood types and stairs models
                        if (stair.lowerCoverWoodType && ['Kide', 'Valo', 'Leiju', 'Kannel'].includes(stair.stairsModel)) {
                            const matchingLowerCoverStorage = storage.filter(item => item.woodType === stair.lowerCoverWoodType && item.length >= step.stepLength && item.thickness > 15 && item.thickness < 25);
                            if (matchingLowerCoverStorage.length === 0) {
                                alert(`Askelmien alakansiin vaadittavaa puuta ei löydy varastosta (${stair.stairName}): ${stair.lowerCoverWoodType} ${step.stepLength}. Lisää tuote varastoon.`);
                                isValid = false; // Set flag to false if validation fails
                            }
                        }

                        // Check if stepWoodType includes "aihio kide" or "aihio valo" and verify matching depth in storage
                        if (step.stepWoodType.includes("aihio kide") || step.stepWoodType.includes("aihio valo")) {
                            const matchingDepthStorage = storage.filter(item => item.woodType === step.stepWoodType && item.depth.toString().trim() === step.stepDepth.toString().trim());

                            // Print out all depths in storage for the matching wood type
                            const allDepths = storage
                                .filter(item => item.woodType === step.stepWoodType)
                                .map(item => item.depth);

                            // If no matching storage found with the correct depth
                            if (matchingDepthStorage.length === 0) {
                                alert(`Ei sopivan syvyistä aihiota varastossa (${stair.stairName}): ${step.stepWoodType} ${step.stepDepth}. Saatavilla olevat syvyydet: ${allDepths.join(', ')}.`);
                                isValid = false; // Set flag to false if validation fails
                            }
                        }
                    }
                });
            });

            // Validate platforms
            stairs.forEach((stair) => {
                stair.platforms.forEach((platform) => {
                    // Check if platformWoodType is null and alert
                    if (!platform.platformWoodType) {
                        alert(`Valitse puulaji tasolle (${platform.platformName}), (${stair.stairName}) `);
                        isValid = false; // Set flag to false if validation fails
                    } else {
                        requiredThickness = 40;
                        // Check if the platform has a valid wood type and length
                        const matchingStorage = storage.filter(item => item.woodType === platform.platformWoodType && item.length >= platform.platformLength && item.thickness === requiredThickness);
                        if (matchingStorage.length === 0) {
                            alert(`Tasoon vaadittavaa puuta ei löydy varastosta (${stair.stairName}): ${platform.platformWoodType} ${platform.platformLength}. Lisää tuote varastoon.`);
                            isValid = false; // Set flag to false if validation fails
                        }

                        // Check if a lower cover wood type is needed
                        if (formData.lowerCoverWoodType && ['Kide', 'Valo', 'Leiju', 'Kannel'].includes(formData.stairsModel)) {
                            const matchingLowerCoverStorage = storage.filter(item => item.woodType === formData.lowerCoverWoodType && item.length >= platform.platformLength && item.thickness > 15 && item.thickness < 25);
                            if (matchingLowerCoverStorage.length === 0) {
                                alert(`Tason alakanteen vaadittavaa puuta ei löydy varastosta (${stair.stairName}): ${formData.lowerCoverWoodType} ${platform.platformLength}. Lisää tuote varastoon.`);
                                isValid = false; // Set flag to false if validation fails
                            }
                        }
                    }
                });
            });

            // Validate square platforms
            stairs.forEach((stair) => {
                stair.squarePlatforms.forEach((squarePlatform) => {
                    if (!squarePlatform.squarePlatformWoodType) {
                        alert(`Valitse puulaji neliötasolle (${squarePlatform.squarePlatformName}), (${stair.stairName}) `);
                        isValid = false; // Set flag to false if validation fails
                    } else {
                        requiredThickness = 40;
                        // Check if the squarePlatform has a valid wood type and length
                        const matchingStorage = storage.filter(item => item.woodType === squarePlatform.squarePlatformWoodType && item.length >= squarePlatform.squarePlatformLength && item.thickness === requiredThickness);
                        if (matchingStorage.length === 0) {
                            alert(`Neliötasoon vaadittavaa puuta ei löydy varastosta (${stair.stairName}): ${squarePlatform.squarePlatformWoodType} ${squarePlatform.squarePlatformLength}. Lisää tuote varastoon.`);
                            isValid = false; // Set flag to false if validation fails
                        }
                        if (formData.lowerCoverWoodType && ['Kide', 'Valo', 'Leiju', 'Kannel'].includes(formData.stairsModel)) {
                            const matchingLowerCoverStorage = storage.filter(item => item.woodType === formData.lowerCoverWoodType && item.length >= squarePlatform.squarePlatformLength && item.thickness > 15 && item.thickness < 25);
                            if (matchingLowerCoverStorage.length === 0) {
                                alert(`Neliötason alakanteen vaadittavaa puuta ei löydy varastosta (${stair.stairName}): ${formData.lowerCoverWoodType} ${squarePlatform.squarePlatformLength}. Lisää tuote varastoon.`);
                                isValid = false; // Set flag to false if validation fails
                            }
                        }
                    }
                });
            });

            // Validate TriangleSteps
            stairs.forEach((stair) => {
                if (stair.L_stairs || stair.U_stairs) {
                    if (!stair.triangleWoodType) {
                        alert(`Valitse puulaji kiertäville askelmille, (${stair.stairName}) `);
                        isValid = false; // Set flag to false if validation fails
                        return;
                    }
                    if (stair.straightSteps.length === 0 || stair.straightSteps.every(step => step.stepLength === null)) {
                        alert('Kiertäviin vaaditaan suorien askelmien mitta');
                        isValid = false;
                        return;
                    }
                    const matchingStorage = storage.filter(item => item.woodType === stair.triangleWoodType && item.length >= Math.min(stair.straightSteps[0].stepLength + 400, 1500));
                    if (matchingStorage.length === 0) {
                        alert(`Kiertäviin vaadittavaa puuta ei löydy varastosta (${stair.stairName}): ${stair.triangleWoodType} ${Math.ceil(Math.min(stair.straightSteps[0].stepLength + 400, 1500) / 100) * 100}. Lisää tuote varastoon.`);
                        isValid = false; // Set flag to false if validation fails
                        return;
                    }
                    if (stair.lowerCoverWoodType && ['Kide', 'Valo', 'Kannel'].includes(stair.stairsModel)) {
                        const matchingLowerCoverStorage = storage.filter(item => item.woodType === stair.lowerCoverWoodType && item.length >= Math.min(stair.straightSteps[0].stepLength + 400, 1500) && item.thickness > 15 && item.thickness < 25);
                        if (matchingLowerCoverStorage.length === 0) {
                            alert(`Kiertävien askelmien alakansiin vaadittavaa puuta ei löydy varastosta (${stair.stairName}): ${stair.lowerCoverWoodType} ${Math.ceil(Math.min(stair.straightSteps[0].stepLength + 400, 1500) / 100) * 100}. Lisää tuote varastoon.`);
                            isValid = false; // Set flag to false if validation fails
                        }
                    }
                }
            });

            // Validate handrails
            stairs.forEach((stair) => {
                stair.handrails.forEach((handrail) => {
                    if (!handrail.handrailWoodType || !handrail.handrailModel) {
                        alert(`Valitse puulaji ja malli käsijohteelle, (${stair.stairName}, ${handrail.handrailName}}) `);
                        isValid = false; // Set flag to false if validation fails
                        return;
                    }
                    const matchingStorage = storage.filter(item =>
                        item.handRail_woodType === handrail.handrailWoodType &&
                        item.handRailModel === handrail.handrailModel
                    );
                    if (matchingStorage.length === 0) {
                        alert(`Käsijohteeseen vaadittavaa puuta ei löydy varastosta (${stair.stairName}): ${handrail.handrailWoodType} ${handrail.handrailModel}. Lisää tuote varastoon.`);
                        isValid = false; // Set flag to false if validation fails
                    }

                });
            });

            if (isValid) {
                const updatedStairs = formData.stairs.map(stair => ({
                    stairName: stair.stairName || '',
                    triangleWoodType: stair.triangleWoodType || '',
                    stepCutting: stair.stepCutting || '',
                    stepCutting_modifiedTimestamp: stair.stepCutting_modifiedTimestamp || null,
                    stepGluing: stair.stepGluing || '',
                    stepGluing_modifiedTimestamp: stair.stepGluing_modifiedTimestamp || null,
                    stepSanding: stair.stepSanding || '',
                    stepSanding_modifiedTimestamp: stair.stepSanding_modifiedTimestamp || null,
                    stepColoring1: stair.stepColoring1 || '',
                    stepColoring1_modifiedTimestamp: stair.stepColoring1_modifiedTimestamp || null,
                    stepColoring2: stair.stepColoring2 || '',
                    stepColoring2_modifiedTimestamp: stair.stepColoring2_modifiedTimestamp || null,
                    stepColoring3: stair.stepColoring3 || '',
                    stepColoring3_modifiedTimestamp: stair.stepColoring3_modifiedTimestamp || null,
                    stepPackaging: stair.stepPackaging || '',
                    stepPackaging_modifiedTimestamp: stair.stepPackaging_modifiedTimestamp || null,
                    handRail_modifiedTimestamp: stair.handRail_modifiedTimestamp || null,
                    handRail: stair.handRail || '',
                    ledStrips: stair.ledStrips || '',
                    lStrips: stair.lStrips || '',
                    ledStrips_modifiedTimestamp: stair.ledStrips_modifiedTimestamp || null,
                    lStrips_modifiedTimestamp: stair.lStrips_modifiedTimestamp || null,
                    stepColor: stair.stepColor || '',
                    ledColor: stair.ledColor || '',
                    stepGroove: stair.stepGroove || '',
                    stairsModel: stair.stairsModel || '',
                    U_stairs: stair.U_stairs || false,
                    L_stairs: stair.L_stairs || false,
                    lowerCoverWoodType: stair.lowerCoverWoodType || '',
                    straightSteps: stair.straightSteps.map(step => ({
                        stepName: step.stepName || '',
                        stepWoodType: step.stepWoodType || '',
                        stepAmount: step.stepAmount || 0,
                        stepLength: step.stepLength || 0,
                        stepDepth: step.stepDepth || 0
                    })),
                    platforms: stair.platforms.map(platform => ({
                        platformName: platform.platformName || '',
                        platformWoodType: platform.platformWoodType || '',
                        platformLength: platform.platformLength || 0
                    })),
                    squarePlatforms: stair.squarePlatforms.map(squarePlatform => ({
                        squarePlatformName: squarePlatform.squarePlatformName || '',
                        squarePlatformWoodType: squarePlatform.squarePlatformWoodType || '',
                        squarePlatformAmount: squarePlatform.squarePlatformAmount || 0,
                        squarePlatformLength: squarePlatform.squarePlatformLength || 0
                    })),
                    handrails: stair.handrails.map(handrail => ({
                        handrailName: handrail.handrailName || '',
                        handrailWoodType: handrail.handrailWoodType || '',
                        handrailModel: handrail.handrailModel || '',
                        handrailAmount: handrail.handrailAmount || 0
                    })),
                    others: stair.others.map(other => ({
                        otherName: other.otherName || '',
                        otherWoodType: other.otherWoodType || '',
                        otherLength: other.otherLength || 0,
                        otherDepth: other.otherDepth || 0,
                        otherThickness: other.otherThickness || 0,
                        otherAmount: other.otherAmount || 0
                    }))
                }));

                // Prepare the order data
                const orderData = {
                    isRemoved: formData.isRemoved,
                    notes: formData.notes,
                    stairs: updatedStairs
                };
                // Dispatch the order update action
                dispatch(updateOrder(orderId, orderData));
                setIsModified(false);
            } else {
                // Optionally, you could handle the case where validation failed but you don't need to dispatch
                console.log("Validation failed. Order was not updated.");
            }
        } else {
            const orderData = {
                isRemoved: formData.isRemoved,
                notes: formData.notes,
                stairs: []
            };
            dispatch(updateOrder(orderId, orderData));
            setIsModified(false);
        }
    };

    useEffect(() => {
        if (isTriangleDisabled) {
            setFormData(prevFormData => ({
                ...prevFormData,
                stairs: prevFormData.stairs.map(stair => ({
                    ...stair,
                    U_stairs: false,
                    L_stairs: false,
                    triangleWoodType: ''
                }))
            }));
        }
    }, [isTriangleDisabled]);

    const addItem = (type, name) => {
        // Define the new item based on the type
        const newItem = {
            stepName: name,
            stepWoodType: '',
            stepAmount: 0,
            stepLength: 0,
            stepDepth: 0
        };

        if (type === 'platforms') {
            newItem.platformName = name;
            newItem.platformWoodType = '';
            newItem.platformAmount = 0;
            newItem.platformLength = 0;
        } else if (type === 'squarePlatforms') {
            newItem.squarePlatformName = name;
            newItem.squarePlatformWoodType = '';
            newItem.squarePlatformAmount = 0;
            newItem.squarePlatformLength = 0;
        } else if (type === 'handrails') {
            newItem.handrailName = name;
            newItem.handrailWoodType = '';
            newItem.handrailModel = '';
            newItem.handrailAmount = 0;
        } else if (type === 'others') {
            newItem.otherName = name;
            newItem.otherLength = 0;
            newItem.otherDepth = 0;
            newItem.otherThickness = 0;
            newItem.otherAmount = 0;
        }

        // Add new item to the selected stair's type array
        selectedStair[type].push(newItem);

        // Update the stairs with the new item
        updatedStairs[selectedStairIndex] = selectedStair;

        // Update formData
        setFormData({
            ...formData,
            stairs: updatedStairs
        });

        if (selectedStair.straightSteps.length > 0) { setSelectedStepIndex(selectedStair.straightSteps.length - 1); }
        if (selectedStair.platforms.length > 0) { setSelectedPlatformIndex(selectedStair.platforms.length - 1); console.log("astetaan indeksi"); }
        if (selectedStair.handrails.length > 0) { setSelectedHandrailIndex(selectedStair.handrails.length - 1); }
        if (selectedStair.squarePlatforms.length > 0) { setSelectedSquarePlatformIndex(selectedStair.squarePlatforms.length - 1); }
        if (selectedStair.others.length > 0) { setSelectedOtherIndex(selectedStair.others.length - 1); }
    };

    const handleNameCancel = () => {
        setShowNameModal(false);
    };

    const handleNameConfirm = (name) => {
        if (currentNameType === 'stair') {
            addStair(name);
        }
        else { addItem(currentNameType, name) }
        setShowNameModal(false);
    };

    const handleNameAddition = (type) => {
        setCurrentNameType(type);
        setShowNameModal(true);
    }

    const handleEditItemName = (index, newName, itemType) => {
        setFormData(prevFormData => {
            const updatedStairs = [...prevFormData.stairs];
            const selectedStair = updatedStairs[selectedStairIndex];

            switch (itemType) {
                case 'straightSteps':
                    selectedStair.straightSteps[index].stepName = newName;
                    break;
                case 'platforms':
                    selectedStair.platforms[index].platformName = newName;
                    break;
                case 'squarePlatforms':
                    selectedStair.squarePlatforms[index].squarePlatformName = newName;
                    break;
                case 'handrails':
                    selectedStair.handrails[index].handrailName = newName;
                    break;
                case 'stairs':
                    updatedStairs[index].stairName = newName;
                    break;
                case 'others':
                    selectedStair.others[index].otherName = newName;
                    break;
                default:
                    console.error('Unknown item type');
                    return prevFormData;
            }

            updatedStairs[selectedStairIndex] = selectedStair;
            return { ...prevFormData, stairs: updatedStairs };
        });
    };

    return (

        <div style={{ margin: '15px' }}>
            <div style={{ marginTop: '20px', justifyContent: 'center' }}>
                <div className="customer-info" style={{ justifyContent: 'center', flexWrap: 'wrap' }}>
                    {loadingOrder ? (
                        <LoadingIcon />
                    ) : (
                        <>
                            {order.number} {order.customer_name}
                            <span style={{ marginLeft: '10px', fontWeight: 'normal' }}>
                                {formattedDate}
                            </span>
                        </>
                    )}
                </div>

            </div>

            <div className="orders-tabs" style={{ justifyContent: 'center' }}>
                <button className={selectedTab === 'Tuotanto' ? 'selected-tab' : 'tab'} onClick={() => setSelectedTab('Tuotanto')}>Tuotanto</button>
                <button className={selectedTab === 'Suunnittelu' ? 'selected-tab' : 'tab'} onClick={() => setSelectedTab('Suunnittelu')}>Suunnittelu</button>
                <button className="gradoGreenButton" style={{ height: '40px', width: '130px' }} type="button" onClick={() => handleNameAddition('stair')}>+ Lisää porras</button>
                {formData.stairs.length > 0 && (
                    <div className="form-group">
                        <label htmlFor="selectedStair">Valitse porras:</label>
                        <CustomDropdown
                            items={formData.stairs.map((stair, index) => ({ name: stair.stairName, index }))}
                            selectedIndex={selectedStairIndex}
                            onChange={(index) => handleStairChange(index)}
                            onDelete={(index) => handleDeleteStair(index)}
                            onEdit={(index, newName) => handleEditItemName(index, newName, 'stairs')}
                        />
                    </div>
                )}
            </div>



            <div className='order-container'>
                <form onSubmit={handleSubmit} className="order-form">
                    {selectedStairIndex !== null && formData.stairs && formData.stairs.length > 0 ? (
                        selectedStair && (
                            <div >
                                {selectedTab === 'Suunnittelu' && (
                                    <div>

                                        <div>
                                            {/* Display form fields for the selected stair */}
                                            <div className="checkbox-field">
                                                <div className="form-group">
                                                    <label htmlFor="stairsModel">Askelmamalli:</label>
                                                    <select
                                                        className="form-input"
                                                        id="stairsModel"
                                                        name="stairsModel"
                                                        value={selectedStair.stairsModel}
                                                        onChange={handleStairModification}
                                                        style={{ width: "180px" }}
                                                        required
                                                    >
                                                        <option value=""></option>
                                                        {stairsModels.map((stairsModel, index) => (
                                                            <option key={index} value={stairsModel}>{stairsModel}</option>
                                                        ))}
                                                    </select>
                                                </div>
                                                <div className="form-group-container">
                                                    <div className="form-group-checkbox">

                                                        <input type="checkbox" id="U_stairs" name="U_stairs" checked={selectedStair?.U_stairs} onChange={handleStairModification} disabled={isTriangleDisabled} />
                                                        <label htmlFor="U_stairs">Kiertävä U-porras</label>
                                                    </div>
                                                    <div className="form-group-checkbox">

                                                        <input type="checkbox" id="L_stairs" name="L_stairs" checked={selectedStair?.L_stairs} onChange={handleStairModification} disabled={isTriangleDisabled} />
                                                        <label htmlFor="L_stairs">Kiertävä L-porras</label>
                                                    </div>
                                                </div>
                                                <div className="add-buttons">
                                                    <button className="gradoGreenButton" type="button" onClick={() => handleNameAddition('straightSteps')}>+ Lisää suoria</button>
                                                    <button className="gradoGreenButton" type="button" onClick={() => handleNameAddition('handrails')}>+ Lisää käsijohteet</button>
                                                </div>
                                                <div className="add-buttons">
                                                    <button className="gradoGreenButton" type="button" onClick={() => handleNameAddition('platforms')}>+ Lisää taso</button>
                                                    <button className="gradoGreenButton" type="button" onClick={() => handleNameAddition('squarePlatforms')}>+ Lisää neliötaso</button>
                                                </div>
                                                <div className="add-buttons">
                                                    <button className="gradoGreenButton" type="button" onClick={() => handleNameAddition('others')}>+ Lisää muu</button>
                                                </div>
                                            </div>
                                            <div className="form-columns">
                                                <div className='form-column'>
                                                    {/*
                                                    <div className="form-group">
                                                        <label htmlFor="stepGroove">Liukuesteuritus:</label>
                                                        <input type="text" className="form-input" id="stepGroove" name="stepGroove" value={selectedStair?.stepGroove || ''} onChange={handleStairModification} />
                                                    </div>
                                                    <div className="form-group">
                                                        <label htmlFor="stepColor">Askelman sävy:</label>
                                                        <input type="text" className="form-input" id="stepColor" name="stepColor" value={selectedStair?.stepColor || ''} onChange={handleStairModification} />
                                                    </div>
                                                    <div className="form-group">
                                                        <label htmlFor="ledColor">LED sävy:</label>
                                                        <input type="text" className="form-input" id="ledColor" name="ledColor" value={selectedStair?.ledColor || ''} onChange={handleStairModification} />
                                                    </div>*/}
                                                    {(selectedStair?.L_stairs || selectedStair?.U_stairs) && (
                                                        <div className="form-group">
                                                            <label htmlFor="triangleWoodType">Kiertävien puulaji:</label>
                                                            <select
                                                                className="form-input"
                                                                id="triangleWoodType"
                                                                name="triangleWoodType"
                                                                value={selectedStair?.triangleWoodType}
                                                                onChange={handleStairModification}
                                                                style={{ width: "190px" }}
                                                                required
                                                            >
                                                                <option value=""></option>
                                                                {woodTypesTriangleStep.map((triangleWoodType, index) => (
                                                                    <option key={index} value={triangleWoodType}>{triangleWoodType}</option>
                                                                ))}
                                                            </select>
                                                        </div>
                                                    )}
                                                    {(selectedStair?.stairsModel === "Kide" || selectedStair?.stairsModel === "Leiju" || selectedStair?.stairsModel === "Valo" || selectedStair?.stairsModel === "Kannel") && (
                                                        <div className="form-group">
                                                            <label htmlFor="lowerCoverWoodType">Alakansien puulaji:</label>
                                                            <select
                                                                className="form-input"
                                                                id="lowerCoverWoodType"
                                                                name="lowerCoverWoodType"
                                                                value={selectedStair?.lowerCoverWoodType}
                                                                onChange={handleStairModification}
                                                                style={{ width: "190px" }}
                                                                required
                                                            >
                                                                <option value=""></option>
                                                                {woodTypesLowercover.map((lowerCoverWoodType, index) => (
                                                                    <option key={index} value={lowerCoverWoodType}>{lowerCoverWoodType}</option>
                                                                ))}
                                                            </select>
                                                        </div>
                                                    )}
                                                </div>
                                                {(selectedStair.straightSteps.length > 0) && (
                                                    <div className="form-column">
                                                        {/* Dropdown to select a straight step */}
                                                        <div className="form-group">
                                                            <label htmlFor="selectedStep">Valitse suorat:</label>
                                                            <CustomDropdown
                                                                items={selectedStair.straightSteps.map((step, index) => ({ name: step.stepName, index }))}
                                                                selectedIndex={selectedStepIndex}
                                                                onChange={(index) => setSelectedStepIndex(index)}
                                                                onDelete={(index) => handleDeleteItem(index, 'straightSteps')}
                                                                onEdit={(index, newName) => handleEditItemName(index, newName, 'straightSteps')}
                                                            />
                                                        </div>

                                                        {/* Only show the form if a step is selected */}
                                                        {selectedStepIndex !== null && (
                                                            <div>
                                                                <div className="form-group">
                                                                    <label htmlFor="stepWoodType">Suorien puulaji:</label>
                                                                    <select
                                                                        className="form-input"
                                                                        id="stepWoodType"
                                                                        name="stepWoodType"
                                                                        value={selectedStraightSteps?.stepWoodType || ''}
                                                                        onChange={(e) => handleWoodObjectModification(e, 'straightSteps')}
                                                                        style={{ width: "180px" }}
                                                                        required
                                                                    >
                                                                        <option value=""></option>
                                                                        {woodTypesStep.map((woodType, index) => (
                                                                            <option key={index} value={woodType}>{woodType}</option>
                                                                        ))}
                                                                    </select>
                                                                </div>
                                                                <div className="form-group">
                                                                    <label htmlFor="stepLength">Suorien pituus:</label>
                                                                    <input
                                                                        type="text"
                                                                        className="form-input"
                                                                        id="stepLength"
                                                                        name="stepLength"
                                                                        value={selectedStraightSteps?.stepLength || ''}
                                                                        onChange={(e) => handleWoodObjectModification(e, 'straightSteps')}
                                                                        pattern="[0-9]*"
                                                                    />
                                                                </div>
                                                                <div className="form-group">
                                                                    <label htmlFor="stepDepth">Suorien syvyys:</label>
                                                                    <input
                                                                        type="text"
                                                                        className="form-input"
                                                                        id="stepDepth"
                                                                        name="stepDepth"
                                                                        value={selectedStraightSteps?.stepDepth || ''}
                                                                        onChange={(e) => handleWoodObjectModification(e, 'straightSteps')}
                                                                        pattern="[0-9]*"
                                                                    />
                                                                </div>
                                                                <div className="form-group">
                                                                    <label htmlFor="stepAmount">Suoria kpl:</label>
                                                                    <input
                                                                        type="text"
                                                                        className="form-input"
                                                                        id="stepAmount"
                                                                        name="stepAmount"
                                                                        value={selectedStraightSteps?.stepAmount || ''}
                                                                        onChange={(e) => handleWoodObjectModification(e, 'straightSteps')}
                                                                        pattern="[0-9]*"
                                                                    />
                                                                </div>
                                                            </div>
                                                        )}
                                                    </div>
                                                )}
                                                {(selectedStair.platforms.length > 0) && (
                                                    <div className="form-column">
                                                        {/* Dropdown to select a platform */}
                                                        <div className="form-group">
                                                            <label htmlFor="selectedPlatform">Valitse taso:</label>
                                                            <CustomDropdown
                                                                items={selectedStair.platforms.map((platform, index) => ({ name: platform.platformName, index }))}
                                                                selectedIndex={selectedPlatformIndex}
                                                                onChange={(index) => setSelectedPlatformIndex(index)}
                                                                onDelete={(index) => handleDeleteItem(index, 'platforms')}
                                                                onEdit={(index, newName) => handleEditItemName(index, newName, 'platforms')}
                                                            />
                                                        </div>


                                                        {/* Only show the form if a platform is selected */}
                                                        {selectedPlatformIndex !== null && (
                                                            <div>
                                                                <div className="form-group">
                                                                    <label htmlFor="platformWoodType">Tason puulaji:</label>
                                                                    <select
                                                                        className="form-input"
                                                                        id="platformWoodType"
                                                                        name="platformWoodType"
                                                                        value={selectedPlatform?.platformWoodType || ''}
                                                                        onChange={(e) => handleWoodObjectModification(e, 'platforms')}
                                                                        style={{ width: "180px" }}
                                                                    >
                                                                        <option value=""></option>
                                                                        {woodTypesPlatform.map((woodType, index) => (
                                                                            <option key={index} value={woodType}>{woodType}</option>
                                                                        ))}
                                                                    </select>
                                                                </div>
                                                                <div className="form-group">
                                                                    <label htmlFor="platformLength">Tason pituus:</label>
                                                                    <input
                                                                        type="text"
                                                                        className="form-input"
                                                                        id="platformLength"
                                                                        name="platformLength"
                                                                        value={selectedPlatform?.platformLength || ''}
                                                                        onChange={(e) => handleWoodObjectModification(e, 'platforms')}
                                                                        pattern="[0-9]*"
                                                                    />
                                                                </div>
                                                            </div>
                                                        )}
                                                    </div>
                                                )}
                                                {(selectedStair.squarePlatforms.length > 0) && (
                                                    <div className="form-column">
                                                        {/* Dropdown to select a square platform */}
                                                        <div className="form-group">
                                                            <label htmlFor="selectedSquarePlatform">Valitse neliötaso:</label>
                                                            <CustomDropdown
                                                                items={selectedStair.squarePlatforms.map((squarePlatform, index) => ({ name: squarePlatform.squarePlatformName, index }))}
                                                                selectedIndex={selectedSquarePlatformIndex}
                                                                onChange={(index) => setSelectedSquarePlatformIndex(index)}
                                                                onDelete={(index) => handleDeleteItem(index, 'squarePlatforms')}
                                                                onEdit={(index, newName) => handleEditItemName(index, newName, 'squarePlatforms')}
                                                            />
                                                        </div>


                                                        {/* Only show the form if a square platform is selected */}
                                                        {selectedSquarePlatformIndex !== null && (
                                                            <div>
                                                                <div className="form-group">
                                                                    <label htmlFor="squarePlatformWoodType">Neliötason puulaji:</label>
                                                                    <select
                                                                        className="form-input"
                                                                        id="squarePlatformWoodType"
                                                                        name="squarePlatformWoodType"
                                                                        value={selectedSquarePlatform?.squarePlatformWoodType || ''}
                                                                        onChange={(e) => handleWoodObjectModification(e, 'squarePlatforms')}
                                                                        style={{ width: "180px" }}
                                                                    >
                                                                        <option value=""></option>
                                                                        {woodTypesPlatform.map((woodType, index) => (
                                                                            <option key={index} value={woodType}>{woodType}</option>
                                                                        ))}
                                                                    </select>
                                                                </div>
                                                                <div className="form-group">
                                                                    <label htmlFor="squarePlatformLength">Neliötason pituus:</label>
                                                                    <input
                                                                        type="text"
                                                                        className="form-input"
                                                                        id="squarePlatformLength"
                                                                        name="squarePlatformLength"
                                                                        value={selectedSquarePlatform?.squarePlatformLength || ''}
                                                                        onChange={(e) => handleWoodObjectModification(e, 'squarePlatforms')}
                                                                        pattern="[0-9]*"
                                                                    />
                                                                </div>
                                                            </div>
                                                        )}
                                                    </div>
                                                )}
                                                {(selectedStair.handrails.length > 0) && (
                                                    <div className="form-column">
                                                        {/* Dropdown to select a handrail */}
                                                        <div className="form-group">
                                                            <label htmlFor="selectedHandrail">Valitse käsijohteet:</label>
                                                            <CustomDropdown
                                                                items={selectedStair.handrails.map((handrail, index) => ({ name: handrail.handrailName, index }))}
                                                                selectedIndex={selectedHandrailIndex}
                                                                onChange={(index) => setSelectedHandrailIndex(index)}
                                                                onDelete={(index) => handleDeleteItem(index, 'handrails')}
                                                                onEdit={(index, newName) => handleEditItemName(index, newName, 'handrails')}
                                                            />
                                                        </div>


                                                        {/* Only show the form if a handrail is selected */}
                                                        {selectedHandrailIndex !== null && (
                                                            <div>
                                                                <div className="form-group">
                                                                    <label htmlFor="handrailWoodType">Käsijohteen puulaji:</label>
                                                                    <select
                                                                        className="form-input"
                                                                        id="handrailWoodType"
                                                                        name="handrailWoodType"
                                                                        value={selectedHandrails?.handrailWoodType || ''}
                                                                        onChange={(e) => handleWoodObjectModification(e, 'handrails')}
                                                                        style={{ width: "180px" }}
                                                                    >
                                                                        <option value=""></option>
                                                                        {handrail_woodTypes.map((woodType, index) => (
                                                                            <option key={index} value={woodType}>{woodType}</option>
                                                                        ))}
                                                                    </select>
                                                                </div>
                                                                <div className="form-group">
                                                                    <label htmlFor="handrailModel">Käsijohteen malli:</label>
                                                                    <select
                                                                        className="form-input"
                                                                        id="handrailModel"
                                                                        name="handrailModel"
                                                                        value={selectedHandrails?.handrailModel || ''}
                                                                        onChange={(e) => handleWoodObjectModification(e, 'handrails')}
                                                                        style={{ width: "180px" }}
                                                                    >
                                                                        <option value=""></option>
                                                                        {handrailModels.map((model, index) => (
                                                                            <option key={index} value={model}>{model}</option>
                                                                        ))}
                                                                    </select>
                                                                </div>
                                                                <div className="form-group">
                                                                    <label htmlFor="handrailAmount">Käsijohdesalkojen määrä:</label>
                                                                    <input
                                                                        type="text"
                                                                        className="form-input"
                                                                        id="handrailAmount"
                                                                        name="handrailAmount"
                                                                        value={selectedHandrails?.handrailAmount || ''}
                                                                        onChange={(e) => handleWoodObjectModification(e, 'handrails')}
                                                                        pattern="[0-9]*"
                                                                    />
                                                                </div>
                                                            </div>
                                                        )}
                                                    </div>
                                                )}
                                                {(selectedStair.others.length > 0) && (
                                                    <div className="form-column">
                                                        {/* Dropdown to select an item */}
                                                        <div className="form-group">
                                                            <label htmlFor="selectedMuu">Valitse muu:</label>
                                                            <CustomDropdown
                                                                items={selectedStair.others.map((other, index) => ({ name: other.otherName, index }))}
                                                                selectedIndex={selectedOtherIndex}
                                                                onChange={(index) => setSelectedOtherIndex(index)}
                                                                onDelete={(index) => handleDeleteItem(index, 'others')}
                                                                onEdit={(index, newName) => handleEditItemName(index, newName, 'others')}
                                                            />
                                                        </div>

                                                        {/* Only show the form if an item is selected */}
                                                        {selectedOtherIndex !== null && (
                                                            <div>
                                                                <div className="form-group">
                                                                    <label htmlFor="otherWoodType">Tuotteen puulaji:</label>
                                                                    <select
                                                                        className="form-input"
                                                                        id="otherWoodType"
                                                                        name="otherWoodType"
                                                                        value={selectedOther?.otherWoodType || ''}
                                                                        onChange={(e) => handleWoodObjectModification(e, 'others')}
                                                                        style={{ width: "180px" }}
                                                                    >
                                                                        <option value=""></option>
                                                                        {otherWoodTypes.map((woodType, index) => (
                                                                            <option key={index} value={woodType}>{woodType}</option>
                                                                        ))}
                                                                    </select>
                                                                </div>
                                                                <div className="form-group">
                                                                    <label htmlFor="otherLength">Valitse koko:</label>
                                                                    <select
                                                                        className="form-input"
                                                                        id="otherLength"
                                                                        name="otherLength"
                                                                        value={selectedOther?.otherLength || ''}
                                                                        onChange={(e) => {
                                                                            const selectedIndex = e.target.selectedIndex;
                                                                            const selectedItem = otherSizes[selectedIndex - 1]; // Skip the empty option
                                                                            handleOtherModification({
                                                                                otherLength: selectedItem.length,
                                                                                otherDepth: selectedItem.depth,
                                                                                otherThickness: selectedItem.thickness
                                                                            }, 'others');
                                                                        }}
                                                                        style={{ width: "180px" }}
                                                                    >
                                                                        <option value=""></option>
                                                                        {otherSizes.map((item, index) => (
                                                                            <option key={index} value={item.length}>
                                                                                {`${item.length} x ${item.depth} x ${item.thickness}`}
                                                                            </option>
                                                                        ))}
                                                                    </select>
                                                                </div>
                                                                <div className="form-group">
                                                                    <label htmlFor="itemAmount">Määrä:</label>
                                                                    <input
                                                                        type="text"
                                                                        className="form-input"
                                                                        id="itemAmount"
                                                                        name="otherAmount"
                                                                        value={selectedOther?.otherAmount || ''}
                                                                        onChange={(e) => handleWoodObjectModification(e, 'others')}
                                                                        pattern="[0-9]*"
                                                                    />
                                                                </div>
                                                            </div>
                                                        )}
                                                    </div>
                                                )}

                                            </div>

                                        </div>

                                    </div>
                                )}
                                {selectedTab === 'Tuotanto' && (
                                    <div className='form-columns'>
                                        <div className="form-column">
                                            <div className="form-row">
                                                <div className="form-group">
                                                    <label htmlFor="stepCutting">Leikkaus:</label>
                                                    <input type="text" className="form-input" id="stepCutting" name="stepCutting" value={selectedStair?.stepCutting || ''} onChange={handleStairModification} />
                                                    <p className="modified-timestamp">{formatTimestamp(selectedStair?.stepCutting_modifiedTimestamp) || ''}</p>
                                                </div>
                                                <div className="form-group">
                                                    <label htmlFor="stepGluing">Liimaus:</label>
                                                    <input type="text" className="form-input" id="stepGluing" name="stepGluing" value={selectedStair?.stepGluing || ''} onChange={handleStairModification} />
                                                    <p className="modified-timestamp">{formatTimestamp(selectedStair?.stepGluing_modifiedTimestamp) || ''}</p>
                                                </div>
                                                <div className="form-group">
                                                    <label htmlFor="stepSanding">Hionta:</label>
                                                    <input type="text" className="form-input" id="stepSanding" name="stepSanding" value={selectedStair?.stepSanding || ''} onChange={handleStairModification} />
                                                    <p className="modified-timestamp">{formatTimestamp(selectedStair?.stepSanding_modifiedTimestamp) || ''}</p>
                                                </div>
                                                <div className="form-group">
                                                    <label htmlFor="handRail">Käsijohteet tehty:</label>
                                                    <input type="text" className="form-input" id="handRail" name="handRail" value={selectedStair?.handRail || ''} onChange={handleStairModification} />
                                                    <p className="modified-timestamp">{formatTimestamp(selectedStair?.handRail_modifiedTimestamp) || ''}</p>
                                                </div>
                                                <div className="form-group">
                                                    <label htmlFor="ledStrips">LED-listat:</label>
                                                    <input type="text" className="form-input" id="ledStrips" name="ledStrips" value={selectedStair?.ledStrips || ''} onChange={handleStairModification} />
                                                    <p className="modified-timestamp">{formatTimestamp(selectedStair?.ledStrips_modifiedTimestamp) || ''}</p>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="form-column">
                                            <div className="form-group">
                                                <label htmlFor="stepColoring">1. värjäys:</label>
                                                <input type="text" className="form-input" id="stepColoring" name="stepColoring1" value={selectedStair?.stepColoring1 || ''} onChange={handleStairModification} />
                                                <p className="modified-timestamp">{formatTimestamp(selectedStair?.stepColoring1_modifiedTimestamp) || ''}</p>
                                            </div>
                                            <div className="form-group">
                                                <label htmlFor="stepColoring">2. värjäys:</label>
                                                <input type="text" className="form-input" id="stepColoring" name="stepColoring2" value={selectedStair?.stepColoring2 || ''} onChange={handleStairModification} />
                                                <p className="modified-timestamp">{formatTimestamp(selectedStair?.stepColoring2_modifiedTimestamp) || ''}</p>
                                            </div>
                                            <div className="form-group">
                                                <label htmlFor="stepColoring">3. värjäys :</label>
                                                <input type="text" className="form-input" id="stepColoring" name="stepColoring3" value={selectedStair?.stepColoring3 || ''} onChange={handleStairModification} />
                                                <p className="modified-timestamp">{formatTimestamp(selectedStair?.stepColoring3_modifiedTimestamp) || ''}</p>
                                            </div>

                                            <div className="form-group">
                                                <label htmlFor="lStrips">L-listat:</label>
                                                <input type="text" className="form-input" id="lStrips" name="lStrips" value={selectedStair?.lStrips || ''} onChange={handleStairModification} />
                                                <p className="modified-timestamp">{formatTimestamp(selectedStair?.lStrips_modifiedTimestamp) || ''}</p>
                                            </div>
                                            <div className="form-group">
                                                <label htmlFor="stepPackaging">Pakkaus:</label>
                                                <input type="text" className="form-input" id="stepPackaging" name="stepPackaging" value={selectedStair?.stepPackaging || ''} onChange={handleStairModification} />
                                                <p className="modified-timestamp">{formatTimestamp(selectedStair?.stepPackaging_modifiedTimestamp) || ''}</p>
                                            </div>
                                        </div>
                                    </div>
                                )}
                            </div>
                        )
                    ) : (
                        <div style={{ marginTop: "20px", display: 'flex', justifyContent: 'center', alignItems: 'center', textAlign: 'center' }}>Ei portaita tässä tilauksessa. Lisää porras yllä olevasta napista.</div>
                    )}
                    <div className='notes-label'>
                        <label htmlFor="notes" className='notes-label'>Huomioita:</label>
                        <textarea
                            className="form-input-field"
                            id="notes"
                            name="notes"
                            value={formData?.notes}
                            onChange={handleFormDataModification}
                            rows="4"
                            style={{ width: "100%" }}
                        />
                    </div>
                    <div className="form-group-checkbox">
                        <input type="checkbox" id="isRemoved" name="isRemoved" checked={formData?.isRemoved} onChange={handleFormDataModification} />
                        <label htmlFor="isRemoved">Poista kalenterista</label>
                    </div>
                    <div className='form-buttons-container'>
                        <button type="button" className="form-button-order form-button-cancel" onClick={handleCancel}>Takaisin</button>
                        <button className="form-button-order form-button-submit" type="submit" disabled={savingOrder} >
                            {savingOrder ? (<LoadingIcon />) : (
                                <> {isSubmitted && !savingOrder && (<FaCheck className="pulse-spin" style={{ position: 'absolute', marginLeft: '80px', color: 'lightgreen', fontSize: '1.2em' }} />)} Tallenna</>
                            )}
                        </button>


                    </div>


                </form>
            </div>
            {showNameModal && <NameInputModal item={currentNameType} onConfirm={handleNameConfirm} onCancel={handleNameCancel} />}
        </div>
    );
};

export default OrderPage;