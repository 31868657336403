import React, { useState } from 'react';
import PersonInputModal from './PersonInputModal';

const AddOther = ({ onSubmit, onClose }) => {
  const [formData, setFormData] = useState({
    woodType: 'Muut',
    item: '',
    limit: '',
    info: '',
    amount: '',
    isHandRail: false,
    isOther: true
  });
  const [showPersonModal, setShowPersonModal] = useState(false);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setShowPersonModal(true); // Show the person input modal
  };

  const handlePersonConfirm = (name) => {
    onSubmit(formData, name); // Pass the person's name to onSubmit
    setShowPersonModal(false);
    onClose(); // Close the AddOther window after submission
  };

  const handlePersonCancel = () => {
    setShowPersonModal(false);
  };

  const handleCancel = () => {
    onClose(); // Close the AddOther window on cancel
  };

  return (
    <div className="add-item-modal" >
      <div className="add-item-window" >
        <h2 style={{ textAlign: 'center' }}>Lisää muu tuote</h2>
        <form onSubmit={handleSubmit} style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', flexWrap: 'wrap' }}>
          <div style={{ marginBottom: '10px' }}>
            <label htmlFor="item-input">Tuote:</label>
            <input
              type="text"
              name="item"
              value={formData.item}
              onChange={handleChange}
              required
              style={{ marginBottom: '10px', maxWidth: '35vw' }}
            />
            <label htmlFor="info-input">Tiedot:</label>
            <input
              type="text"
              name="info"
              value={formData.info}
              onChange={handleChange}
              required
              style={{ marginBottom: '10px', maxWidth: '35vw' }}
            />
          </div>
          <div style={{ marginBottom: '10px' }}>
            <label htmlFor="limit-input">Varmuusraja:</label>
            <input
              type="text"
              name="limit"
              value={formData.limit}
              onChange={handleChange}
              required
              style={{ marginBottom: '10px', maxWidth: '35vw' }}
            />
            <label htmlFor="amount-input">Määrä:</label>
            <input
              type="number"
              name="amount"
              value={formData.amount}
              onChange={handleChange}
              required
              style={{ marginBottom: '10px', maxWidth: '35vw' }}
            />
          </div>
          <div style={{ width: '100%', textAlign: 'center' }}>
            <button type="submit" className="form-button form-button-new">Lisää tuote</button>
            <button type="button" onClick={handleCancel} className="form-button form-button-cancel">Peru</button>
          </div>
        </form>
      </div>
      {showPersonModal && <PersonInputModal item={"Lisää muu tuote"} onConfirm={handlePersonConfirm} onCancel={handlePersonCancel} />}
    </div>
  );
};

export default AddOther;
