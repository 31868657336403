import React, { useState } from 'react';
import PersonInputModal from './PersonInputModal'; // Import the new modal component

const ModifyAmount = ({ initialAmount, onSubmit, onCancel, item, image }) => {

  const [amount, setAmount] = useState(initialAmount);
  const [count, setCount] = useState(0);
  const [inputValue, setInputValue] = useState(initialAmount.toString());
  const [showPersonModal, setShowPersonModal] = useState(false);

  const handleDecrease = () => {
    const newAmount = amount - 1;
    setAmount(newAmount);
    setInputValue(newAmount.toString()); // Update inputValue to reflect amount
    setCount(newAmount - initialAmount); // Update count based on difference
  };

  const handleIncrease = () => {
    const newAmount = amount + 1;
    setAmount(newAmount);
    setInputValue(newAmount.toString()); // Update inputValue to reflect amount
    setCount(newAmount - initialAmount); // Update count based on difference
  };

  const handleChangeAmount = (change) => {
    const newAmount = amount + change;
    setAmount(newAmount);
    setInputValue(newAmount.toString()); // Update inputValue to reflect amount
    setCount(newAmount - initialAmount); // Update count based on difference
  };

  const handleSubmit = () => {
    setShowPersonModal(true); // Show the person input modal
  };

  const handlePersonConfirm = (name) => {
    onSubmit(amount, item, name); // Pass the person's name to onSubmit
    setShowPersonModal(false);
  };

  const handlePersonCancel = () => {
    setShowPersonModal(false);
  };

  const handleCancel = () => {
    onCancel();
  };

  const handleInputChange = (value) => {
    setInputValue(value); // Update input field with raw value

    // Check if the input contains only valid characters (numbers, +, -, *, /)
    if (/^[\d+\-*/().\s]*$/.test(value)) {
      try {
        // Evaluate the expression safely
        const evaluatedValue = eval(value); // Calculate the value
        if (!isNaN(evaluatedValue)) {
          setAmount(evaluatedValue); // Set the calculated value to amount
          setCount(evaluatedValue - initialAmount); // Update count
        }
      } catch (error) {
        // Handle any errors from eval
        console.error("Väärä syöte: ", error);
      }
    }
  };

  return (
    <div className="modify-amount-modal">
      <div className="modify-amount-window">
        <h2>Muuta määrää</h2>
        <div className="item-details" style={{ fontWeight: 'bold', color: 'black' }}>
          {item.isOther ? (
            <>
              <p>{item.item}</p>
              <p>{item.info}</p>
            </>
          ) : (
            item.isHandRail ? (
              <>
                <p>{item.woodType}</p>
                <p>{item.handRail_woodType} {item.handRailModel}</p>
                <p>{item.length}mm</p>
              </>
            ) : (
              <>
                <p>{item.woodType}</p>
                <p>{item.length}mm x {item.depth}mm</p>
                <p>{item.thickness}mm</p>
              </>
            )
          )}
        </div>
        <div className="counter" style={{ color: count < 0 ? 'red' : count > 0 ? 'green' : 'transparent', fontWeight: 'bold' }}>
          {count > 0 ? `+${count}` : count}
        </div>
        <div className="amount-controls" style={{ display: 'flex', alignItems: 'center'}}>
          <div style={{ display: 'flex', flexDirection: 'column' }}>
            <button className="small-minus" onClick={() => handleChangeAmount(-5)}>-5</button>
            <button className="small-minus" onClick={() => handleChangeAmount(-10)}>-10</button>
          </div>
          <button className="minus" onClick={handleDecrease}>-1</button>
          <input
            className="input"
            type="text"
            value={inputValue}
            onChange={(e) => handleInputChange(e.target.value)}
          />
          <button className="plus" onClick={handleIncrease}>+1</button>
          <div style={{ display: 'flex', flexDirection: 'column' }}>
            <button className="small-plus" onClick={() => handleChangeAmount(5)}>+5</button>
            <button className="small-plus" onClick={() => handleChangeAmount(10)}>+10</button>
          </div>
        </div>
        <div className="buttons">
          <button className="cancel" style={{ color: 'red', width: '120px', fontWeight: 'bold' }} onClick={handleCancel}>Peru</button>
          <button className="submit" style={{ color: 'green', width: '120px', fontWeight: 'bold' }} onClick={handleSubmit}>Hyväksy</button>
        </div>
      </div>
      {showPersonModal && <PersonInputModal item={"Muuta määrää"} onConfirm={handlePersonConfirm} onCancel={handlePersonCancel} />}
    </div>
  );
};

export default ModifyAmount;
