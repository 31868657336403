import React, { useState } from 'react';
import PersonInputModal from './PersonInputModal';

const ModifyProduct = ({ formData, onSubmit, onDelete, onClose, onAddition }) => {
    const [modifiedData, setModifiedData] = useState(formData || {
        woodType: '',
        length: '',
        depth: '',
        thickness: '',
        limit: '',
        amount: '',
        handRail_woodType: '',
        handRailModel: '',
        isHandRail: false,
        item: '',
        info: ''
    });
    const [showPersonModal, setShowPersonModal] = useState(false);
    const [showPersonModalDeletion, setShowPersonModalDeletion] = useState(false);
    const [showPersonModalAddition, setShowPersonModalDAddition] = useState(false);
    const [isAddition, setIsAddition] = useState(false);

    const handleChange = (e) => {
        const { name, value, type, checked } = e.target;
        setModifiedData({ ...modifiedData, [name]: type === 'checkbox' ? checked : value });
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        setShowPersonModal(true); // Show the person input modal
    };

    const handlePersonConfirm = (name) => {
        if (isAddition) {
            onAddition(modifiedData, name);
        } else {
            onSubmit(modifiedData, name); // Pass the person's name to onSubmit
        }
        setShowPersonModal(false);
        onClose(); // Close the ModifyProduct window after submission
    };

    const handlePersonCancel = () => {
        setShowPersonModal(false);
        setShowPersonModalDeletion(false);
        setShowPersonModalDAddition(false);
    };

    const handleDelete = () => {
        // Show the delete confirmation modal
        setShowPersonModalDeletion(true);
    };

    const handlePersonConfirmDelete = (person) => {
        onDelete(modifiedData._id, person);
        setShowPersonModal(false);
        onClose(); // Close the ModifyProduct window after deletion
    }

    const handleCancel = () => {
        onClose(); // Close the ModifyProduct window on cancel
    };

    const handleAddition = () => {
        setIsAddition(true);
        setShowPersonModalDAddition(true);
    };

    return (
        <div className="modify-product-modal" style={{ position: 'fixed', top: 0, left: 0, width: '100%', height: '100%', backgroundColor: 'rgba(0, 0, 0, 0.5)', zIndex: 999, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            <div className="modify-product-window" style={{ width: '450px', backgroundColor: 'white', padding: '20px', borderRadius: '5px', boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.2)' }}>
                <h2 style={{ textAlign: 'center' }}>Muokkaa tuotetta</h2>
                <form onSubmit={handleSubmit} style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', flexWrap: 'wrap' }}>
                    <div style={{ flex: '1', marginRight: '10px' }}>
                        {modifiedData.woodType === 'Muut' ? (
                            <>
                                <label htmlFor="item">Tuote:</label>
                                <input
                                    type="text"
                                    name="item"
                                    value={modifiedData.item}
                                    onChange={handleChange}
                                    required
                                    style={{ marginBottom: '10px', maxWidth: '35vw' }}
                                />
                                <label htmlFor="info">Tiedot:</label>
                                <input
                                    type="text"
                                    name="info"
                                    value={modifiedData.info}
                                    onChange={handleChange}
                                    style={{ marginBottom: '10px', maxWidth: '35vw' }}
                                />
                            </>
                        ) : modifiedData.woodType === 'Käsijohteet' ? (
                            <>
                                <label htmlFor="handRail_woodType">Kaiteen puulaji:</label>
                                <input
                                    type="text"
                                    name="handRail_woodType"
                                    value={modifiedData.handRail_woodType}
                                    onChange={handleChange}
                                    required
                                    style={{ marginBottom: '10px', maxWidth: '35vw' }}
                                />
                                <label htmlFor="handRailModel">Kaiteen malli:</label>
                                <input
                                    type="text"
                                    name="handRailModel"
                                    value={modifiedData.handRailModel}
                                    onChange={handleChange}
                                    required
                                    style={{ marginBottom: '10px', maxWidth: '35vw' }}
                                />
                                <label htmlFor="length">Pituus:</label>
                                <input
                                    type="text"
                                    name="length"
                                    value={modifiedData.length}
                                    onChange={handleChange}
                                    required
                                    style={{ marginBottom: '10px', maxWidth: '35vw' }}
                                />
                            </>
                        ) : (
                            <>
                                <label htmlFor="woodType">Puulaji:</label>
                                <input
                                    type="text"
                                    name="woodType"
                                    value={modifiedData.woodType}
                                    onChange={handleChange}
                                    required
                                    style={{ marginBottom: '10px', maxWidth: '35vw' }}
                                />
                                <label htmlFor="length">Pituus:</label>
                                <input
                                    type="text"
                                    name="length"
                                    value={modifiedData.length}
                                    onChange={handleChange}
                                    required
                                    style={{ marginBottom: '10px', maxWidth: '35vw' }}
                                />
                                <label htmlFor="depth">Leveys:</label>
                                <input
                                    type="text"
                                    name="depth"
                                    value={modifiedData.depth}
                                    onChange={handleChange}
                                    required
                                    style={{ marginBottom: '10px', maxWidth: '35vw' }}
                                />
                            </>
                        )}
                    </div>
                    <div style={{ flex: '1', marginLeft: '10px' }}>
                    {modifiedData.woodType !== 'Muut' && modifiedData.woodType !== 'Käsijohteet' && (
                            <>
                                <label htmlFor="thickness">Paksuus:</label>
                                <input
                                    type="text"
                                    name="thickness"
                                    value={modifiedData.thickness}
                                    onChange={handleChange}
                                    required
                                    style={{ marginBottom: '10px', maxWidth: '35vw' }}
                                />
                            </>
                        )}
                        <label htmlFor="limit">Varmuusraja:</label>
                        <input
                            type="text"
                            name="limit"
                            value={modifiedData.limit}
                            onChange={handleChange}
                            required
                            style={{ marginBottom: '10px', maxWidth: '35vw' }}
                        />
                        <label htmlFor="amount">Määrä:</label>
                        <input
                            type="number"
                            name="amount"
                            value={modifiedData.amount}
                            onChange={handleChange}
                            required
                            min="0"
                            style={{ marginBottom: '10px', maxWidth: '35vw' }}
                        />
                    </div>
                    <div style={{ width: '100%', display: 'flex', justifyContent: 'space-between' }}>
                        <button type="submit" className="form-button form-button-submit">Tallenna muutokset</button>
                        <button type="button" className="form-button form-button-new" onClick={handleAddition}>Lisää uusi tuote</button>
                    </div>
                    <div style={{ width: '100%', display: 'flex', justifyContent: 'space-between', marginTop: '10px' }}>
                        <button type="button" className="form-button form-button-cancel" onClick={handleCancel}>Peru</button>
                        <button type="button" className="form-button form-button-delete" onClick={handleDelete}>Poista tuote</button>
                    </div>
                </form>
            </div>
            {showPersonModal && <PersonInputModal item={"Muokkaa tuotetta"} onConfirm={handlePersonConfirm} onCancel={handlePersonCancel} />}
            {showPersonModalDeletion && <PersonInputModal item={"Poista tuote"} onConfirm={handlePersonConfirmDelete} onCancel={handlePersonCancel} />}
            {showPersonModalAddition && <PersonInputModal item={"Lisää uusi tuote"} onConfirm={handlePersonConfirm} onCancel={handlePersonCancel} />}
        </div>
    );
    
};

export default ModifyProduct;